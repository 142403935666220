import { Box, makeStyles, Popover, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
		width: 200,
		padding: 16,
	}
}))

function FeedbackPopover({anchor, feedback, onClose}) {
	const classes = useStyles();

	return (
		<Popover
			id="feedbackPopover"
			open={anchor !== null && feedback !== null && feedback !== ""}
			anchorEl={anchor}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
		>
			<Box className={classes.root}>
				<Typography align="center">
					{feedback}
				</Typography>
			</Box>
		</Popover>
	);
}

export default FeedbackPopover;