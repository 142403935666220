import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        height: '3px', 
        borderRadius: '50px', 
        background: theme.palette.primary.main,
        width: '60px',
    },
}));

export default function Divider({align="left", marginBottom="0"}) {
    const classes = useStyles();
    return (
        <div 
            className={classes.root}
            style={align === "center" ? 
                {margin: `0 auto ${marginBottom} auto`} : (
                    (align === "right" ? 
                    {margin: `0 0 ${marginBottom} auto`} : 
                    {margin: `0 auto ${marginBottom} 0`}
                    )
                )
            }
        />
    )
}