import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HeaderContext = createContext();

export const HeaderProvider = (props) => {
	const [icons, setIcons] = useState([]);
	const location = useLocation();
	const [lastLocation, setLastLocation] = useState(null);

	useEffect(() => {
		if (lastLocation === null || lastLocation.pathname !== location.pathname) {
			setIcons([]);
			setLastLocation(location);
		}
	}, [location, lastLocation]);

	const value = {
		icons,
		setIcons,
	};

	return (
		<HeaderContext.Provider value={value}>
			{props.children}
		</HeaderContext.Provider>
	);
};

export const useHeader = () => {
	const context = useContext(HeaderContext);

	if (!context)
		throw new Error("useHeader must be used inside a `HeaderProvider`");

	return context;
};
