export const FILTER = {
    ALPHABET: "ALPHABET",
    CATEGORY: "CATEGORY",
}

export const CATEGORY = {
    OTHERS: "OTHERS",
    FRUITS: "FRUITS",
    VEGATABELS: "VEGATABELS",
    REFRIGIATED: "REFRIGIATED",
    BAKED: "BAKED",
    DRINKS: "DRINKS",
    SWEETS: "SWEETS",
}

export const STOCK_TYPE = {
    UNLIMITED: "UNLIMITED",
    PIECES: "PIECES",
    WEIGHT: "WEIGHT",
}

export const PRICE_UNIT = {
    WEIGHT: "WEIGHT",
    PIECE: "PIECE",
}

export const TAXES = {
    NULL: "NULL",
    SIEBEN: "SIEBEN",
    TENPOINTSEVEN: "TENPOINTSEVEN",
    NEUNZEHN: "NEUNZEHN",
}

export const ORGANIC_CERTIFICATION = {
    EU: "EU",
    BIOLAND: "BIOLAND",
    DEMETER: "DEMETER",
    NATURLAND: "NATURLAND",
    BIOKREIS: "BIOKREIS",
    BIOPARK: "BIOPARK",
    GAEA: "GAEA",
    ECOVIN: "ECOVIN",
    BIO_SUISSE: "BIO_SUISSE",
    ECOLAND: "ECOLAND",
    ECOVEG: "ECOVEG",
    BIO_AUSTRIA: "BIO_AUSTRIA",
}

export const FAIRTRADE_CERTIFICATION = {
    FAIRTRADE: "FAIRTRADE",
    FAIRTRADE_WITH_ARROW: "FAIRTRADE_WITH_ARROW",
    FAIRTRADE_GOLD: "FAIRTRADE_GOLD",
    FAIRTRADE_INGREDIENTS: "FAIRTRADE_INGREDIENTS",
    FAIRTRADE_COCOA: "FAIRTRADE_COCOA",
    FAIRTRADE_COTTON: "FAIRTRADE_COTTON",
    FAIRTRADE_RICE: "FAIRTRADE_RICE",
    FAIRTRADE_SUGAR: "FAIRTRADE_SUGAR",
    GEPA_FAIR_PLUS: "GEPA_FAIR_PLUS",
    WFTO: "WFTO",
    NATURLAND_FAIR: "NATURLAND_FAIR",
    HAND_IN_HAND: "HAND_IN_HAND",
    FAIR_FOR_LIFE: "FAIR_FOR_LIFE",
    FAIR_TRADE_CERTIFIED: "FAIR_TRADE_CERTIFIED",
    RAINFOREST_ALLIANCE_CERTIFIED: "RAINFOREST_ALLIANCE_CERTIFIED",
    UTZ: "UTZ",
}

export const REGIONAL_TYPE = {
    PRODUCED: "PRODUCED",
    PROCESSED: "PROCESSED",
}

export const DISTANCE = {
    LOWER50: "LOWER50",
    LOWER100: "LOWER100",
    GREATER100: "GREATER100",
}