import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { createContext, useContext, useEffect, useState } from "react";
import { useStorage } from "../useStorage";

const oidcSettings = {
  authority: window._env_.RS_OIDC_AUTHORITY,
  client_id: window._env_.RS_OIDC_CLIENT_ID,
  redirect_uri: window.location.protocol + "//" + window.location.host.concat("/callback"),
  client_secret: window._env_.RS_OIDC_CLIENT_SECRET,
  post_logout_redirect_uri: window.location.protocol + "//" + window.location.host,
  scope: "openid offline user.name user.contact user.birthday user.payment",
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true
};

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [userManager, setUserManager] = useState(null);
  const [user, setUser] = useState(undefined);
  const [origin, setOrigin] = useStorage(window.localStorage, 'origin');

  const getUser = async (currentUserManager) => {
    try {
      const user = await currentUserManager.getUser();
      setUser(user);
    } catch (error) {
      console.log("Error in getting User" + error);
    }
  };

  useEffect(() => {
    let currentUserManager = userManager;
    if (currentUserManager === null) {
      currentUserManager = new UserManager({userStore: new WebStorageStateStore({ store: window.localStorage }), ...oidcSettings})
      currentUserManager.startSilentRenew()
      setUserManager(currentUserManager);
    }
    getUser(currentUserManager);
  }, [userManager]);

  const isInitialized = () => {
    if (user !== undefined && userManager !== null) {
      return true;
    }
    return false;
  }

  const login = () => {
    setOrigin(window.location.pathname)
    userManager.signinRedirect();
  };

  const register = () => {
    setOrigin(window.location.pathname)
    userManager.signinRedirect({extraQueryParams: {type:"register"}});
  };

  const logout = () => userManager.removeUser().then(() => setUser(null));

  const callback = async () => {
    const user = await userManager.signinRedirectCallback();
    setUser(user);
    return origin;
  };

  const loggedIn = () => {
    if (user === undefined || user === null) {
      return false;
    } else if (user.expires_at && (user.expires_at * 1000 < Date.now())) {
      return false;
    }
    return true;
  };

  const request = (url, options, routeToRegistration=false) => {
    if (!loggedIn()) {
      if (routeToRegistration) {
        register();
      } else {
        login();
      }
      
    } else {
      options.headers = options.headers || {};
      options.headers['Authorization'] = "Bearer " + user.access_token;
    }
    return fetch(url,options);
  }

  const value = {
    user,
    isInitialized,
    loggedIn,
    login,
    register,
    logout,
    callback,
    request
  };

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error('useAuth must be used inside a `AuthProvider`');

  return context;
}