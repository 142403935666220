/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, ButtonGroup, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOG_LEVEL } from "../../enums/log";
import { FILTER } from "../../enums/product";
import { theme } from "../../theme";
import FilterPanel from "../utilities/filterPanel";
import Logger from "../utilities/logger";
import SearchBar from "../utilities/searchBar";
import ProductCard from "./productCard";
import ProductFlipCard from "./productFlipCard";

// eslint-disable-next-line no-unused-vars
const logger = new Logger({ level: LOG_LEVEL.INFO, target: "productlist" });
const useStyles = makeStyles(theme => ({
    root: props => ({
        height: props.fullscreen ? `calc(100vh - 2rem - ${props.offset}rem)` : `calc("100% - ${props.offset}rem)`,
        display: "flex",
        flexDirection: "column",
    }),
    list: {
        marginTop: 8,
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: "none",
        flex: 2,
    },
    expandIcon: {
        width: "100%",
        marginBottom: -20,
        textAlign: "center",
    },
    buttonGroupFilterAnimalBreed: {
        '& > *': {
            fontSize: "clamp(0.7rem, 1.1vw ,1.3rem)",
            textTransform: "unset !important",
        },
        textAlign: "center",
    },
    buttonFilterAnimalBreed: {
        borderRadius: 0,
    },
    filterButton: props => ({
        padding: 0,
        minWidth: `calc(100% / ${props.filterLength})`,
        fontSize: 16,
    }),
}))



function ProductList({
    products, 
    filter, // alphabet or category
    filterAnimalBreed = [],
    searchBar,
    showStock,
    onListItemClick, 
    offset, 
    fullscreen,
    infiniteScroll,
    showBuyButton = false,
    showShoppingCart = false,
    showRegionalLabels = true,
    isFlipCard = false,
    sortSoldOut = false,
    showInfoButton = false,
}) {
    const { t } = useTranslation();
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const mobile = useMediaQuery('(max-width:600px)')
    const [categories, setCategories] = useState(new Map());
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [activeCategory, setActiveCategory] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [animalBreedFilterValues, setAnimalBreedFilterValues] = useState([]);
    const [index, setIndex] = useState(infiniteScroll ? 50 : products.length);
    const loader = useRef(null);
    let currentHeader = "";
    let filterIndex = -1;

    const classes = useStyles({
        offset: offset, 
        filterLength: filter === FILTER.ALPHABET ? alphabet.length : filter === FILTER.CATEGORY ? categories.size : 0, 
        fullscreen: fullscreen,
        smallScreen: mobile,
    });

    useEffect(() => {
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
           observer.observe(loader.current)
        }
    }, []);

    useEffect(() => {
        setCategories(getCategories(products))
    }, [products])

    useEffect(() => {
        if (infiniteScroll) {
            setIndex(50);
        } else {
            setIndex(products.length);
        }
        if(searchValue.length > 0){
            setFilteredProducts(products.filter((product) => filterProduct(product)));
        }else{
            setFilteredProducts(products.filter((product) => filterAnimalBreedCategories(product)));
        }
        
    }, [products, searchValue, activeCategory, animalBreedFilterValues])

    const handleObserver = (entities) => {
        const target = entities[0];
        if (infiniteScroll && target.isIntersecting) {   
            setIndex((index) => index + 50);
        }
    }

    const filterProduct = (product) => {
        if (product !== null) {
            if (activeCategory !== null && product.category !== activeCategory) {
                return false;
            }
            if (searchValue.length > 0) {
                return product.title.toLowerCase().includes(searchValue.toLowerCase());
            }
        }
        return true;
    }

    const filterAnimalBreedCategories = (product) => {
        if (product !== null) {
            if (activeCategory !== null && product.category !== activeCategory) {
                return false;
            }
            
            if (animalBreedFilterValues.length > 0) {
                for(var i = 0; i < animalBreedFilterValues.length; i++){
                    if(product.title.toLowerCase().includes(animalBreedFilterValues[i].toLowerCase())){
                        return true;
                    }
                }
                return false;
            }
        }
        return true;
    }

    const getCategories = (products) => {
        var categories = new Map();
        if (filter === FILTER.CATEGORY) {
            products.forEach(product => {
                if (!categories.has(product.category)) {
                    categories.set(product.category, t("webProduct.category.".concat(product.category),product.category));
                }
            });
        }
        return categories;
    }

    const getAnchor = (product) => {
        let anchors = [];
        let currentIndex = filter ===  FILTER.ALPHABET ? alphabet.length - 1 : filter ===  FILTER.CATEGORY ? categories.size - 1 : -1;
        let categoryArray = [];
        if (filter ===  FILTER.CATEGORY && categories !== null) {
            categoryArray = Array.from(categories.keys());
        }
        if(filterProduct(product)) {
            if (product != null) {
                if (filter ===  FILTER.ALPHABET) {
                    currentIndex = alphabet.indexOf(product.title[0].toLowerCase());
                } else if (filter ===  FILTER.CATEGORY) {
                    currentIndex = categoryArray.indexOf(product.category);
                }
            }
            while (filterIndex !== currentIndex && currentIndex > -1) {
                filterIndex = filterIndex + 1;
                if (filter ===  FILTER.ALPHABET) {
                    anchors.push(<Box id={alphabet[filterIndex]} key={alphabet[filterIndex]} />);
                } else if (filter ===  FILTER.CATEGORY) {
                    anchors.push(<Box id={categoryArray[filterIndex]} key={categoryArray[filterIndex]} />);
                }
            }
        }
        return anchors
    }

    const getHeader = (product) => {
        var title = replaceSpecialCharacters(product.title).toUpperCase();
        var newHeader = currentHeader;
        if (filterProduct(product)){
            if (filter ===  FILTER.ALPHABET && title[0] !== currentHeader && alphabet.indexOf(title[0].toLowerCase()) > -1) {
                if (title[0] !== currentHeader && alphabet.indexOf(title[0].toLowerCase()) > -1) {
                    newHeader = title[0];
                }
            } else if (filter ===  FILTER.CATEGORY) {
                const header = t("webProduct.category.".concat(product.category),product.category);
                if (header !== currentHeader) {
                    newHeader = header;
                }
            }
        }
        if (newHeader !== currentHeader) {
            currentHeader = newHeader;
            return (
                <Grid key={"productListHeading" + currentHeader} item xs={12}>
                    <Typography variant="body1">
                        {currentHeader}
                    </Typography>
                </Grid>
            ) 
        }
        return null;
    }

    const replaceSpecialCharacters = (title) => {
        title = title.toLowerCase();
        title = title.replace("ä", "ae");
        title = title.replace("ö", "oe");
        title = title.replace("ü", "ue");
        title = title.replace("ß", "ss");
        return title;
    }

    const search = (value) => {
        setSearchValue(value);
        setAnimalBreedFilterValues([]);
    }

    return (
        <Box className={classes.root}>
            { searchBar ? <SearchBar value={searchValue} onSearch={search} /> : null }
            {filter ===  FILTER.ALPHABET ? 
                <ButtonGroup variant="text" color="primary" size="medium" aria-label="text primary button group">
                    {[...alphabet].map((letter) => (
                        <Button key={"filter" + letter} className={classes.filterButton} href={"#" + letter}>{letter.toUpperCase()}</Button>
                    ))}
                </ButtonGroup>
             : filter ===  FILTER.CATEGORY ? 
                <FilterPanel labels={categories} activeLabel={activeCategory} handleClick={setActiveCategory} />
             : null}
             {Object.keys(filterAnimalBreed).length > 0 ? 
                <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        padding: theme.spacing(4),
                        }}>
                    <div className={classes.buttonGroupFilterAnimalBreed} variant="contained" aria-label="outlined primary button group">
                        <Button
                            className={classes.buttonFilterAnimalBreed}
                            key={"filterChipAlles"}
                            label={"Alles"}
                            variant={searchValue === "" && animalBreedFilterValues.length === 0 ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => {search(""); setAnimalBreedFilterValues([]);}}
                            // onDelete={key === activeLabel ? () => handleClick(null) : undefined}
                            clickable
                        >Alles</Button>
                        {Object.keys(filterAnimalBreed).map((value) => (
                            <Button
                                className={classes.buttonFilterAnimalBreed}
                                key={"filterChip" + value}
                                label={value}
                                variant={animalBreedFilterValues[0] === filterAnimalBreed[value][0] ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => {
                                    setAnimalBreedFilterValues(filterAnimalBreed[value]);
                                    setSearchValue("");
                                }}
                                // onDelete={key === activeLabel ? () => handleClick(null) : undefined}
                                clickable
                            >{value}</Button>
                        ))}
                    </div>
                </div>
             : null}
            <Grid container spacing={theme.spacing(4)} className={classes.list}>
                    {filteredProducts.slice(0, index >= filteredProducts.length ? filteredProducts.length : index)
                    .sort(function(productX, productY){if(sortSoldOut){return Number(productY.product.stock !== 0) - Number(productX.product.stock !== 0)}else{return 0}})
                    .map((product, index) => ([
                        getAnchor(product),
                        getHeader(product),
                        <Grid key={"product_" + index} hidden={!filterProduct(product)} item xs={12} sm={showShoppingCart ? 12 : 6} md={showShoppingCart ? 12 : 4} lg={showShoppingCart ? 6 : 4} xl={showShoppingCart ? 6 : 4}>
                            {isFlipCard ? <ProductFlipCard 
                                product={product} 
                                showStock={showStock} 
                                onCardClick={onListItemClick}
                                showBuyButton={showBuyButton} 
                                showRegionalLabels={showRegionalLabels} 
                            /> : <ProductCard 
                                product={product} 
                                showStock={showStock} 
                                onCardClick={onListItemClick}
                                showBuyButton={showBuyButton} 
                                showRegionalLabels={showRegionalLabels}
                                showInfoButton={showInfoButton} />
                            }
                        </Grid>
                    ]))}
                {getAnchor(null)}
                {filteredProducts.length <= 0 && searchValue.length > 0 ? 
                    <Grid key="searchNoResults" item xs={12}>
                        <Typography variant="body1" align="center">
                            Keine Produkte gefunden.
                        </Typography>
                    </Grid>
                : null}
            </Grid>
            <Box className={classes.expandIcon} ref={loader} hidden={!infiniteScroll && !fullscreen}>
                <Typography variant="body1" hidden={!infiniteScroll || index >= filteredProducts.length}>Lade weitere Produkte ...</Typography>
                <ExpandMoreIcon color="disabled" fontSize="large" />
            </Box>
        </Box>
    );
}

export default ProductList;