import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
    },
    content: {
        maxWidth: 1152,
        marginBlock: 40,
        marginInline: "auto",
        paddingInline: 15,
    },
    link: {
        color: theme.palette.secondary.main,
    },
}))

function AGB(props) {
    const classes = useStyles();
    const company = {
        name: window._env_.RS_COMPANY_NAME,
        street: window._env_.RS_COMPANY_STREET,
        postalcode: window._env_.RS_COMPANY_POSTALCODE,
        city: window._env_.RS_COMPANY_CITY,
        country: window._env_.RS_COMPANY_COUNTRY,
        manager: window._env_.RS_COMPANY_MANAGER,
        website: window._env_.RS_COMPANY_WEBSITE,
        telephone: window._env_.RS_COMPANY_TEL,
        mail: window._env_.RS_COMPANY_MAIL,
}
    const softwareOwner = window._env_.RS_SOFTWARE_OWNER
    

    return (
        <Box className={classes.root}>
            <Box id="agb" className={classes.content}>
                <Typography variant="h4" gutterBottom color="primary" >Allgemeine Geschäftsbedingungen</Typography>
                <Typography variant="h5" gutterBottom>Geltungsbereich</Typography>
                <Typography variant="body1" gutterBottom>Diese Geschäftsbedingungen gelten für das Registrieren, Betreten und den Kauf von Waren der {company.name} ({company.street}, {company.postalcode} {company.city}).</Typography>
                <Typography variant="h5" gutterBottom>Vertragsabschluss</Typography>
                <Typography variant="body1" gutterBottom>Die Präsentation unserer Waren und der Einräumung der Möglichkeit zum Kauf stellt unsererseits ein konkretes Angebot zum Abschluss eines Kaufvertrages dar. </Typography>
                <Typography variant="body1" gutterBottom>Durch die Entnahme der Ware nehmen Sie das Angebot an, verpflichten sich die Ware an der Kasse abzurechnen und der Kaufvertrag ist zustande gekommen. Verpflichtend für den Kaufvertrag ist bei Unterschieden zwischen Preisschild und Kassensystem immer der Preis des Kassensystems.</Typography>
                <Typography variant="body1" gutterBottom>Nach dem Abrechnen an der Kasse erhalten Sie eine Rechnung per E-Mail an die von Ihnen angegebene E-Mail-Adresse. </Typography>
                <Typography variant="h5" gutterBottom>Zahlung</Typography>
                <Typography variant="body1" gutterBottom>Die Bezahlung erfolgt an der Selbstbedienerkasse mittels Lastschrift.</Typography>
                <Typography variant="h5" gutterBottom>Unterrichtungs- und Anzeigepflichten des Kundenkarteninhabers</Typography>
                <Typography variant="body1" gutterBottom>Die Kundenkarte der {company.name}und insbesondere der PIN darf nicht an Dritte weitergegeben werden. Das Betreten des Geschäfts und das Benutzen der Selbstbedienerkasse ist nur Personen erlaubt, die sich über die Internetseite der {company.name} (<a className={classes.link} href={"https://" + company.website}>{company.website}</a>) registriert haben. Für entstehenden Schaden durch das Weitergeben der Kundenkarte und des PIN haftet der Kartenbesitzer.</Typography>
                <Typography variant="body1" gutterBottom>Stellt der Kundenkartenbesitzer den Verlust oder Diebstahl seiner Kundenkarte fest oder eine sonstige nicht autorisierte Nutzung der Kundenkarte oder PIN, so ist die {company.name} umgehend zu informieren. Der Kundenkartenbesitzer hat einen Diebstahl oder Missbrauch unverzüglich bei der Polizei anzuzeigen.</Typography>
                <Typography variant="h5" gutterBottom>Haftung</Typography>
                <Typography variant="body1" gutterBottom>(1) Bei leichter Fahrlässigkeit haftet {company.name} nur bei der Verletzung vertragswesentlicher Pflichten und beschränkt auf den vorhersehbaren Schaden. Diese Beschränkung gilt nicht bei der Verletzung von Leben, Körper und Gesundheit. Für sonstige leicht fahrlässig durch einen Mangel des Kaufgegenstandes verursachte Schäden haftet {company.name} nicht.</Typography>
                <Typography variant="body1" gutterBottom>(2) Unabhängig von einem Verschulden von {company.name} bleibt eine Haftung von {company.name} bei arglistigem Verschweigen des Mangels oder aus der Übernahme einer Garantie unberührt. Die Herstellergarantie ist eine Garantie des Herstellers und stellt keine Übernahme einer Garantie durch {company.name} dar.</Typography>
                <Typography variant="body1" gutterBottom>(3) Ausgeschlossen ist die persönliche Haftung der gesetzlichen Vertreter, Erfüllungsgehilfen und Betriebsangehörigen von {company.name} für von ihnen durch leichte Fahrlässigkeit verursachte Schäden. </Typography>
                <Typography variant="h5" gutterBottom>Jugendschutz</Typography>
                <Typography variant="body1">In der RegioStore-Filiale werden alkoholische Getränke verkauft. Um sicherzustellen, dass Minderjährige keinen Zugriff auf alkoholische Getränke haben, ist die Registrierung nur Volljährigen gestattet. Das Weitergeben der Karte an Minderjährige ist nicht gestattet, da vor Ort nicht geprüft werden kann, ob die einkaufende Person volljährig ist.</Typography>
                <Typography variant="h5" gutterBottom>Streitbeilegung</Typography>
                <Typography variant="body1" gutterBottom>Allgemeine Informationspflichten zur alternativen Streitbeilegung nach Art. 14 Abs. 1 ODR-VO und § 36 VSBG (Verbraucherstreitbeilegungsgesetz):</Typography>
                <Typography variant="body1" gutterBottom>Die europäische Kommission stellt eine Plattform zur Online-Streitbelegung (OS) zur Verfügung, die Sie unter <a href="http://ec.europa.eu/consumers/odr/" className={classes.link}>dieser Adresse</a> finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und auch nicht bereit. </Typography>
                <Typography variant="h5" gutterBottom>Schlussbestimmungen</Typography>
                <Typography variant="body1" gutterBottom>(1) Sollten einzelne Bestimmungen dieses Vertrages ganz oder teilweise unwirksam oder nichtig sein oder werden, so wird dadurch die Wirksamkeit des Vertrages im Übrigen nicht berührt, insoweit ein Vertragspartner hierdurch nicht unangemessen benachteiligt wird.</Typography>
                <Typography variant="body1" gutterBottom>(2) Änderungen oder Ergänzungen werden an die von Ihnen angegebene Mailadresse geschickt.</Typography>
                <Typography variant="body1" gutterBottom>Im Übrigen gilt die am Eingang der RegioStore-Filiale ausgehängte Hausordnung.</Typography>
            </Box>
            <Box id="privacy" className={classes.content}>
                <Typography variant="h4" gutterBottom color="primary">Datenschutzerklärung</Typography>
                <Typography variant="h5" gutterBottom>Geltungsbereich</Typography>
                <Typography variant="body1" gutterBottom>Diese Datenschutzerklärung dient dazu alle Nutzer der Webseiten der {company.name} und der Kunden in den Filialen der {company.name} gemäß Bundesdatenschutzgesetz und Telemediengesetz über die Art, Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch die {company.name} ({company.street}, {company.postalcode} {company.city}) zu informieren.</Typography>
                <Typography variant="body1" gutterBottom>Die {company.name} nimmt ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften.</Typography>
                <Typography variant="body1" gutterBottom>Bedenken Sie, dass die Datenübertragung im Internet grundsätzlich mit Sicherheitslücken bedacht sein kann. Ein vollumfänglicher Schutz vor dem Zugriff durch Fremde ist nicht realisierbar.</Typography>
                <Typography variant="body1" gutterBottom>Alle nachfolgenden Daten werden im Rahmen der Verarbeitung auch an Drittfirmen zur Verarbeitung weitergegeben werden. Folgende Unternehmen sind dies:</Typography>
                <Typography variant="body1" gutterBottom>
                    <ul>
                        <li>{softwareOwner} (Softwareentwicklung Warenwirtschaft)</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom>Zugriffsdaten</Typography>
                <Typography variant="body1" gutterBottom>Als Webseitenbetreiber der Homepage <a href={"https://" + company.website} className={classes.link}>{company.website}</a> erheben wir Daten über die Zugriffe und speichern diese als Logfiles. Folgende Daten werden gespeichert:</Typography>
                <Typography variant="body1" gutterBottom>
                    <ul>
                        <li>Besuchte Webseite</li>
                        <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
                        <li>Quelle/Verweis, wen welchem Sie auf die Webseite gelangt sind</li>
                        <li>Verwendeter Browser inklusive Browser-Version</li>
                        <li>Verwendetes Betriebssystem</li>
                        <li>Verwendete IP-Adresse</li>
                    </ul>
                </Typography>
                <Typography variant="body1" gutterBottom>Die erhobenen Daten dienen lediglich statistischen Auswertungen und zur Verbesserung der Webseite. Der Webesitebetreiber behält sich allerdings vor, die Server-Logfiles nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</Typography>
                <Typography variant="h5" gutterBottom>Umgang mit personenbezogenen Daten</Typography>
                <Typography variant="body1" gutterBottom>Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen.</Typography>
                <Typography variant="body1" gutterBottom>Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.</Typography>
                <Typography variant="h5" gutterBottom>Bestellvorgang</Typography>
                <Typography variant="body1" gutterBottom>Um dem Kunden in Zukunft ein besseres Sortiment und individuelle Werbeangebot zukommen zu lassen, speichern und verarbeiten wir die Zusammensetzung der Einkäufer jedes Kunden. </Typography>
                <Typography variant="body1" gutterBottom>Gespeichert wird die Zusammensetzung des Einkaufs und die Kundennummer des Käufers.</Typography>
                <Typography variant="h5" gutterBottom>Schutz vor Diebstahl</Typography>
                <Typography variant="body1" gutterBottom>Um bei einem möglichen Diebstahl das Vergehen einer Kundenkarte zuzuordnen, speichern wir das Datum inklusive Uhrzeit des Nutzens der Kundenkarte. So können wir trotz Tarnung durch eine Maske oder ähnlichem einen Diebstahl einer Kundenkarte zuordnen.</Typography>
                <Typography variant="body1" gutterBottom>
                    Gespeichert werden:
                    <ul>
                        <li>Kundenkartennummer</li>
                        <li>Datum und Uhrzeit des Nutzens der Kundenkarte zur Türöffnung</li>
                        <li>Videoüberwachung</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom>Videoüberwachung der Filiale</Typography>
                <Typography variant="body1" gutterBottom>Um bei einem Diebstahl der Polizei und Versicherung Beweismaterial vorlegen zu können, speichern wir das Video unser Überwachungskameras. Die Speicherung kann dabei bis zu 10 Tage betragen, weil bei unserem Ladenkonzept ohne aktives Personal im Ladenlokal ein Diebstahl erst bei einer vollständigen Inventur auffällt, die aufgrund des hohen Aufwands nur alle 10 Tage durchgeführt wird.</Typography>
                <Typography variant="body1" gutterBottom>Bei einem Diebstahl bzw. konkreten Verdachtsfall können die Aufnahmen länger gespeichert werden. Bei einem Verdachtsfall werden die Videos auch der Polizei und der Versicherung zugespielt.</Typography>
                <Typography variant="h5" gutterBottom>Kundendaten</Typography>
                <Typography variant="body1" gutterBottom>Um frühzeitig potenzielle neue Standorte zu identifizieren, speichern wir die Adressen der Kunden und nutzen diese für die Standortfindung. Dadurch können wir abschätzen an welchen Standorten eine weitere Filiale auf ein hohes Interesse stoßen könnte.</Typography>
            </Box>
        </Box>
    );
}

export default AGB