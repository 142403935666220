import { Box, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";

function DeliveryLockerInput({ locker, deliveryDates, onChange }) {
	const [lockers, setLockers] = useState([]);

	useEffect(() => {
		let lockers = [];
		if (deliveryDates !== null && deliveryDates.length > 0) {
			lockers = deliveryDates
				.map((deliveryDate) => deliveryDate.locker)
				.filter(
					(locker, index, array) =>
						array.findIndex((l) => l.id === locker.id) === index
				);
		}
		setLockers(lockers);
	}, [deliveryDates]);

	const getLockerDisplayText = (locker) => {
		const addressText =
			locker.street + ", " + locker.postalCode + " " + locker.city;
		return locker.title + " (" + addressText + ")";
	};

	return (
		<Box>
			<Select
				value={locker && locker !== null ? locker.id : ""}
				style={{ display: "block", textAlign: "center" }}
				onChange={(e) => {
					onChange(e.target.value);
				}}
			>
				<MenuItem key={"locker0"} value={""} component={Box}>
					Keine Packstation ausgewählt
				</MenuItem>
				{lockers.map((locker) => (
					<MenuItem
						key={"locker" + locker.id}
						value={locker.id}
						component={Box}
					>
						{getLockerDisplayText(locker)}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
}

export default DeliveryLockerInput;
