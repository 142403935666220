import { Box, Divider, Grid, makeStyles } from "@material-ui/core";
import CheckoutItem from "./checkoutItem";

const useStyles = makeStyles(theme => ({
    root: {
        overflowY: "scroll",
        overflowX: "hidden",
        scrollbarWidth: "none",
    },
}))

function CheckoutList(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container>
                {props.checkoutItems.map((checkoutItem, index) => (
                    <Grid key={"checkoutItem" + index} item xs={12}>
                        <CheckoutItem color="primary" index={index} item={checkoutItem} image={false} onChangeQuantity={props.onChangeQuantity} />
                        <Divider />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default CheckoutList;