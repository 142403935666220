import { Box, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
	root: {
		paddingInline: 16,
		paddingBlock: 8,
	},
	listContainer: {
		paddingBlock: 8,
	},
	done: {
		paddingRight: 0,
	},
	optional: {
		color: theme.palette.background.main,
	},
	listHeading: {
		fontWeight: "bold"
	},
}));

function DeliveryCartCheckoutItem({number, heading, done, optional=false, children}) {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Grid container>
				<Grid item container className={classes.listContainer}>
					<Grid item xs={1}>
						<Chip
							variant={done ? "default" : "outlined"}
							size="small"
							label={done ? null : number ? number : 0}
							icon={done ? <DoneIcon /> : null}
							color={done || optional ? "secondary" : "primary"}
							classes={done ? {label: classes.done} : optional ? {label: classes.optional} : null}
						/>
					</Grid>
					<Grid item xs={11}>
						<Typography className={classes.listHeading} align="center" gutterBottom>
							{heading}
						</Typography>
						{children}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default DeliveryCartCheckoutItem;