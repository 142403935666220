import { Box, Button, ButtonGroup, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { PRICE_UNIT } from "../../enums/product";
import { theme } from "../../theme";
import ProductImage from "../products/productImage";
import { getAmount, getDisplayPrice, getWeightText } from "../products/productUtils";
import { formatNumber, round } from "../utilities/numberUtils";
import QuantityControl from "../utilities/quantityControl";

const useStyles = makeStyles(theme => ({
	root: {
		paddingInline: 16,
		paddingBlock: 8,
	},
	flex: {
		display: "flex",
		height: "100%",
		alignItems: "center"
	},
	text: {
		width: "100%"
	},
	scaleAmount: {
		marginInlineStart: 10,
	},
	discountChip: {
		margin: "0 0 0 auto",
	}
}));

function CheckoutItem({index, color, item, image, quantitySteps=1, onClick=() => {}, onChangeQuantity}) {
	const classes = useStyles();

	const priceUnit = item.priceUnit;
	const title = item.description;
	const quantity = item.quantity;
	const discount = item.discount;
	const price = getDisplayPrice(
		item.priceUnit,
		item.buyingPricePerUnit,
	);
	const amount = getAmount(
		item.priceUnit, 
		item.quantity,
		item.buyingPricePerUnit,
		item.discount,
	);

	const hasImage = () => {
		return image !== false;
	}

	return (
		<Box className={classes.root} onClick={() => onClick(item)}>
			<Grid container spacing={theme.spacing(2)}>
			{ hasImage() && <Grid item xs={false} sm={2} md={1}>
				<ProductImage src={image !== null ? window._env_.RS_HOST.concat(image) : null} />
			</Grid> }
			<Grid item container xs={12} sm={hasImage() ? 10 : 12} md={hasImage() ? 11 : 12} spacing={theme.spacing(1)}>
				<Grid item xs={12}>
					<Box className={classes.flex}>
						<Typography variant="body1">
							{title}
						</Typography>
						{discount > 0 ?
							<Chip 
								size="small" 
								color="secondary"
								className={classes.discountChip}
								label={formatNumber(round(discount * 100), 2) + " % Rabatt"}
							/> : null
						}
					</Box>
				</Grid>
				<Grid item xs={5}>
					{priceUnit === PRICE_UNIT.WEIGHT && quantitySteps === 1 ?
						<Box className={classes.flex}>
							<ButtonGroup color={color} aria-label="outlined primary button group">
								<Button variant="outlined" color={color} size="small" fill="clear" onClick={(e) => onChangeQuantity(e, index, e.currentTarget, 0)}>
									<DeleteIcon slot="icon-only" fontSize="small" />
								</Button>
							</ButtonGroup>
							<Typography variant="body1" className={classes.scaleAmount}>
								{getWeightText(quantity)}
							</Typography>
						</Box> :
						<Box className={classes.flex}>
							<QuantityControl color={color} index={index} unit={priceUnit === PRICE_UNIT.WEIGHT ? "kg" : null} quantity={quantity} quantitySteps={quantitySteps} onChangeQuantity={onChangeQuantity} />
						</Box>
					}
				</Grid>
				<Grid item xs={5}>
					<Box className={classes.flex}>
						<Typography variant="body1" className={classes.text}>
							{"x " + price}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={2}>
					<Box className={classes.flex}>
						<Typography variant="body1" className={classes.text} align="right">
							{formatNumber(amount, 2) + " €"}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			</Grid>
		</Box>
	);
}

export default CheckoutItem;