/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { LOG_LEVEL } from "../../enums/log";
import Logger from "../utilities/logger";

function InactiveDialog({open, timeoutTime, secondsLeft, onTimeout, onCancel}) {
    const [seconds, setSeconds] = useState(secondsLeft);
    const [attentionSoundPlayed, setAttentionSoundPlayed] = useState(false);

    const logger = new Logger({ level: LOG_LEVEL.INFO, target: "inactivedialog" });

    useEffect(() =>{
        if (!attentionSoundPlayed){
            logger.debug("inactivity dialog is displayed. play attention sound.");
            const attentionSound = new Audio("sounds/attention.wav");
            attentionSound.play();
            setAttentionSoundPlayed(true);
        }
        const timer = setInterval(() => {
            if(seconds > 1) {
                logger.debug(seconds - 1, "seconds left");
                setSeconds(seconds - 1);
            } else {
                logger.debug("timeout");
                onTimeout();
            }
        }, 1000);
        return () => clearInterval(timer)
    }, [
        logger,
        seconds,
        onTimeout,
        attentionSoundPlayed
    ]);

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullWidth
            maxWidth="sm"
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" onClose={onCancel}>
                Bist du noch da?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Seit <Typography component="span" color="primary">{timeoutTime} Sekunden</Typography> gab es keine Aktivität mehr von dir. In <Typography component="span" color="primary">{seconds} Sekunden</Typography>  loggen wir dich automatisch aus.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onCancel}>
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default InactiveDialog;