import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from "@material-ui/core";

function RemovedItemsDialog({ open, items, handleClose }) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
			aria-labelledby="dialog-title"
		>
			<DialogTitle id="dialog-title" onClose={handleClose}>
				Einige Artikel nicht mehr verfügbar
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="body1" gutterBottom>
							Leider sind einige deiner ausgewählten Artikel bereits nicht mehr
							verfügbar. Folgende Produkte haben wir daher aus deinem Warenkorb
							entfernt:
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography component="ul" gutterBottom>
							{items &&
								items.map((item) => (
									<Typography component="li">
										{item.quantity +
											(item.priceUnit === "WEIGHT" ? " kg " : " x ") +
											item.description}
									</Typography>
								))}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={handleClose}>
					Schließen
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default RemovedItemsDialog;
