import { makeStyles } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: props => ({
        width: props.arrowLength * 2,
        height: props.arrowLength * 2,
        display: "flex",
        flexDirection: "column",
        flexGrow: 0,
        transform: `rotate(${props.degree}deg)`,
    }),
    line: props => ({
        width: props.lineWidth,
        height: props.lineLength,
        marginInline: "auto",
        backgroundColor: theme.palette.primary.main,
        animation: props.blinking ? "1s soft-blink infinite alternate" : "none",
        position: "relative",
    }),
    arrow: props => ({
        content: "",
        marginInline: "auto",
        marginTop: (props.lineLength + (props.lineWidth * 0.25) - (props.lineLength - props.arrowLength) / 2) * -1,
        borderBottom: `${props.lineWidth}px solid ${theme.palette.primary.main}`,
        borderRight: `${props.lineWidth}px solid ${theme.palette.primary.main}`,
        borderTop: `${props.lineWidth}px solid transparent`,
        borderLeft: `${props.lineWidth}px solid transparent`,
        animation: props.blinking ? "1s soft-blink infinite alternate": "none",
        width: props.arrowLength,
        height: props.arrowLength,
        transform: "rotate(45deg)",
    }),
}));

function Arrow({left, right, top, bottom, blinking, lineWidth, arrowLength}) {
    // Standard: Bottom
    let degree = 0;
    if(left && top) {
        degree = 135;
    } else if (left && bottom) {
        degree = 45;
    } else if (right && top) {
        degree = 225;
    } else if (right && bottom) {
        degree = 315;
    } else if (left) {
        degree = 90;
    } else if (right) {
        degree = 270;
    } else if (top) {
        degree = 180;
    }
    const classes = useStyles({
        degree: degree,
        blinking: blinking,
        arrowLength: arrowLength,
        lineLength: (arrowLength + (2 * lineWidth)) * Math.sqrt(2),
        lineWidth: lineWidth,
    });
    return (
        <div className={classes.root}>
            <div className={classes.line} />
            <div className={classes.arrow} />
        </div>
    );
}

export default Arrow;