import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
	root: {
		minHeight: 25,
		display: "flex",
		justifyContent: "left",
		position: "absolute",
		bottom: 5,
		left: 5,
	},
	labelImage: {
		width: "100%",
		maxWidth: 30,
		height: "100%",
		maxHeight: 30,
		marginInline: 5,
		marginBlock: "auto",
	}
});

function ProductLabel({className, labels, inspection}) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={className}>
			{labels && labels.map((label) => (
				<Tooltip key={"Tooltip" + label}  title={t("label.".concat(label),label)}>
					<img 
						key={label} 
						alt={label} 
						className={classes.labelImage}
						ratio={1} 
						src={"/labels/" + label.toLowerCase() + ".png"} 
					/>
				</Tooltip>
			))}
			{inspection && <Typography component="span">
				{inspection}
			</Typography>}
		</div>
	);
}

export default ProductLabel;