import { Box, makeStyles, Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import React, { useState } from "react";
import BarcodeReader from 'react-barcode-reader';
import { LOG_LEVEL } from "../../enums/log";
import Logger from "../utilities/logger";

const useStyles = makeStyles(theme => ({
    alert: {
        paddingBlock: 15,
        paddingInline: 25,
    },
}));

function ProductScanner({scanDisabled, onProductScan, onProductScanAfter}) {
    const classes = useStyles();
    const [errorText, setErrorText] = useState("");
    const [warningSound] = useState(new Audio("sounds/warning.wav"));

    const logger = new Logger({ level: LOG_LEVEL.INFO, target: "productscanner" });

    const handleScan = (barcode) => {
        logger.debug("handle product scan");
        if (!scanDisabled && barcode.length !== 10) {
            logger.info("handle product scan with barcode", barcode);
            onProductScan();
            let error = true;
            var targetUrl = window._env_.RS_HOST.concat("/api/v1/product?barcode=" + barcode);
            fetch(encodeURI(targetUrl), {
                method: 'GET',
            }).then(response => {
                if(response.ok) {
                    error = false;
                    return response.json();
                } else if (response.status === 404) {
                    logger.warn("Product with barcode", barcode, "not found");
                    warningSound.play();
                    setErrorText("Das Produkt konnte nicht in der Datenbank gefunden werden.");
                } else {
                    logger.warn("Unknown error in get product request with barcode", barcode, "(/api/v1/product) Statuscode:", response.status);
                }
            }).then(product => {
                if (!error) {
                    onProductScanAfter(product);
                } else {
                    onProductScanAfter(null);
                }
            }).catch(function(error) {
                logger.error("Error in get product request with barcode", barcode, "(/api/v1/product):", error);
                warningSound.play();
                setErrorText("Fehler beim Abrufen der Produktinformationen: " + error);
                onProductScanAfter(null);
            });
        } else {
            logger.debug("product scan disabled or barcode length is 10");
        }
    }

    const handleError = (err) => {
        logger.debug("handle error");
        logger.error("error on product scan:", err);
        if (err !== null && err.trim() !== "") {
            setErrorText("Es gab einen Fehler beim Scannen des Produkts:" + err);
        }
    }

    const handleScanErrorClose = () => {
        logger.debug("handle scan error close");
        setErrorText("");
    }

    return (
        <Box>
            <BarcodeReader
                onError={handleError}
                onScan={handleScan}
            />
            <Snackbar
                id="productScanErrorSnackbar"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorText !== ""}
                autoHideDuration={10000} 
                onClose={handleScanErrorClose}
            >
                <Alert 
                    id="productScanErrorAlert"
                    variant="filled"
                    className={classes.alert}
                    onClose={handleScanErrorClose} 
                    severity="error"
                >
                    {errorText}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default ProductScanner