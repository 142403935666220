import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import DeliveryDateInput from "./deliveryDateInput";
import DeliveryLockerInput from "./deliveryLockerInput";

const useStyles = makeStyles(theme => ({
	input: {
		marginBlockStart: 16,
	},
	listHeading: {
		fontWeight: "bold"
	},
}));

function DeliveryDateDialog({open, deliveryDates, onClose}) {
	const classes = useStyles();
	const { dispatch } = useContext(AppContext);
	const [lockers, setLockers] = useState([]);
	const [locker, setLocker] = useState(null);
	const [deliveryDate, setDeliveryDate] = useState(null);
	const [remainingMinutes, setRemainingMinutes] = useState(0);

	useEffect(() => {
		let lockers = [];
		if (deliveryDates !== null && deliveryDates.length > 0) {
			lockers = deliveryDates
				.map((deliveryDate) => deliveryDate.locker)
				.filter((locker,index,array) => array.findIndex(l => l.id === locker.id) === index);
		}
		setLockers(lockers);
	},[deliveryDates])

	const handleDeliveryDateChange = (id) => {
		let deliveryDate = null;
		if (id !== null && id !== '') {
			deliveryDate = deliveryDates[deliveryDates.map((deliveryDate) => deliveryDate.id).indexOf(id)];
		}
		if (deliveryDate === null) {
			if (locker !== null) {
				setLocker(null);
			}
		} else {
			if (locker == null || deliveryDate.locker.id !== locker.id) {
				setLocker(deliveryDate.locker);
			}
		}
		setDeliveryDate(deliveryDate);
	}

	const handleLockerChange = (id) => {
		let locker = null;
		if (id !== null && id !== '') {
			locker = lockers[lockers.map((locker) => locker.id).indexOf(id)];
		}
		if (locker !== null && deliveryDate && deliveryDate !== null && deliveryDate.locker.id !== locker.id) {
			setDeliveryDate(null);
		}
		setLocker(locker);
	}

	const savePossible = () => {
		return deliveryDate && 
			deliveryDate !== null &&
			deliveryDate.freeCompartments > 0 &&
			remainingMinutes > 0;
	}

	const saveDeliveryDate = () => {
		dispatch({
			type: 'changeCartDeliveryDate',
			deliveryDate: deliveryDate
		});
		onClose();
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			aria-labelledby="dialog-title"
		>
			<DialogTitle id="dialog-title" onClose={onClose}>
				Lieferzeit auswählen
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1" align="center">
					Wir liefern ausschließlich an unsere gekühlten Packstationen. Du kannst hier direkt eine Packstation und Lieferzeit auswählen oder erst einmal in unserem Sortiment stöbern.
				</Typography>
				<Box className={classes.input}>
					<Typography className={classes.listHeading} color="primary" align="center" gutterBottom>
						Packstation
					</Typography>
					<DeliveryLockerInput
						locker={locker}
						deliveryDates={deliveryDates}
						onChange={handleLockerChange}
					/>
				</Box>
				<Box className={classes.input}>
					<Typography className={classes.listHeading} color="primary" align="center" gutterBottom>
						Lieferzeit
					</Typography>
					<DeliveryDateInput 
						deliveryDate={deliveryDate}
						deliveryDates={deliveryDates}
						locker={locker}
						onRemainingMinutesChange={(minutes) => setRemainingMinutes(minutes)}
						onChange={handleDeliveryDateChange}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="primary" onClick={onClose}>
					Erstmal stöbern
				</Button>
				<Button variant="contained" color="secondary" disabled={!savePossible()} onClick={saveDeliveryDate}>
					Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DeliveryDateDialog;