import { Box, List, ListItem, ListItemIcon, ListItemText, makeStyles, Popover, Typography } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { getWeightText } from "../products/productUtils";
import Divider from "../utilities/divider";
import { getCartItems } from "./deliveryAction";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: 12,
		paddingBlockEnd: 0,
	}
}))

function StoragePopover({anchor, product, onClose}) {
	const classes = useStyles();
	const { state, dispatch } = useContext(AppContext);
	const [storage, setStorage] = useState([]);
	const [storageInCart, setStorageInCart] = useState([]);
	const [storageNotInCart, setStorageNotInCart] = useState([]);
	
	useEffect(() => {
		if (product && product !== null) {
			let error = true;
			fetch(window._env_.RS_HOST.concat("/api/v1/product/" + product.productId + "/storage"), {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(response => {
				if(response.ok) {
					error = false;
					return response.json();
				}
			}).then(data => {
				if (!error && data && data !== null) {
					setStorage(data)
				}
			}).catch(function(error) {
				console.log(error);
			});
		}
	}, [product]) 

	useEffect(() => {
		let articles = [];
		let articlesInCart = [];
		if (product && product !== null && storage && storage !== null && storage.length > 0) {
			articles = storage.slice();
			getCartItems(state,product.productId).forEach(item => {
				if (item.codedBarcode && item.codedBarcode !== null) {
					let index = articles.map((article) => article.individualBarcode).indexOf(item.codedBarcode);
					if (index >= 0) {
						articlesInCart.push(articles[index]);
						articles.splice(index, 1);
					}
				}
			});
		}
		setStorageInCart(articlesInCart)
		setStorageNotInCart(articles);
	}, [state, product, storage])

	const addToCart = (article, quantity) => {
		dispatch({
			type: 'addToCart',
			product: product,
			barcode: article.individualBarcode,
			quantity: quantity * article.weight
		});
		onClose();
	}

	return (
		<Popover
			id="storagePopover"
			open={anchor !== null}
			anchorEl={anchor}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
		>
			<Box className={classes.root}>
				<Typography variant="h6" gutterBottom>
					Wähle deine Menge:
				</Typography>
				<Divider />
			</Box>
			<List>
				{storageInCart
					.sort((article1, article2) => article1.weight - article2.weight)
					.map((article) => (
					<ListItem key={"storage" + article.id} button onClick={() => addToCart(article, -1)}>
						<ListItemIcon>
							<CheckIcon color="primary" /> 
						</ListItemIcon>
						<ListItemText primary={getWeightText(article.weight)} />
					</ListItem>
				))}
				{storageNotInCart
					.sort((article1, article2) => article1.weight - article2.weight)
					.map((article) => (
					<ListItem key={"storage" + article.id} button onClick={() => addToCart(article, 1)}>
						<ListItemIcon />
						<ListItemText primary={getWeightText(article.weight)} />
					</ListItem>
				))}
				<ListItem key="closePopover" button onClick={onClose}>
					<ListItemText primary="Schließen" primaryTypographyProps={{align: "center", color:"primary"}}/>
				</ListItem>
			</List>
		</Popover>
	);
}

export default StoragePopover;