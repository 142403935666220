import { makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
    root: {
        position: "fixed",
        width: "100%",
        top: 0,
        left: 0,
        height: 4,
        zIndex: 1000,
        backgroundRepeat: "no-repeat",
    },
});

function getScrollPosition() {
    var h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight';
    return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100
}

export function ScrollProgressBar(props) {
    const theme = useTheme();
    const classes = useStyles();
    const [scroll, setScroll] = useState(getScrollPosition());

    var handleScroll = () => {
        setScroll(getScrollPosition());
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll )
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    })

    return (
        <div className={classes.root} style={{ background: `linear-gradient(to right, ${theme.palette.primary.main} ${scroll}%, transparent 0)` }}></div>
    )
}