/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { LOG_LEVEL } from "../../enums/log";
import Logger from "../utilities/logger";

const useStyles = makeStyles(theme => ({
    root: props => ({
        display: props.display ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh', 
        background: props.success ? "#4caf50" : "#f44336",
        zIndex: 1,
    }),
    icon: {
        color: "white",
        fontSize: "10rem",
    },
    text: {
        color: "white",
        width: "75%",
        whiteSpace: "pre-line",
    }
}));

export default function FeedbackScreen({open=false, success=true, duration=10, text, onClose=() => {}}) {
    const mobile = useMediaQuery('(max-width:600px)')
    const [displayFeedback, setDisplayFeedback] = useState(false);
    const [secondsToDisplay, setSecondsToDisplay] = useState(duration);
    const classes = useStyles({display: displayFeedback, success: success});

    const logger = new Logger({ level: LOG_LEVEL.INFO, target: "feedbackscreen" });

    useEffect(() => {
        if(open) {
            if(!displayFeedback && secondsToDisplay > 1) {
                setDisplayFeedback(true);
            }
            const timer = setInterval(() => {
                if(secondsToDisplay > 1) {
                    logger.debug(secondsToDisplay - 1, "seconds left");
                    setSecondsToDisplay(secondsToDisplay - 1);
                } else {
                    logger.debug("close Feedback");
                    setDisplayFeedback(false);
                    onClose();
                }
            }, 1000);
            return () => clearInterval(timer)
        } else {
            if(secondsToDisplay !== duration) {
                setSecondsToDisplay(duration);
            }
            logger.debug("No Feedback to display");
        }
    })

    return (
        <div className={classes.root}>
            <FontAwesomeIcon className={classes.icon} icon={success ? ["fas", "check"] : ["fas", "times"]} />
            <Typography align="center" className={classes.text} variant={mobile ? "body1" : "h4"}>{text}</Typography>
        </div>
    )
}