import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Arrow from "../utilities/arrow";
import CardScanner from "./cardScanner";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: `calc(100vh - 1rem)`,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column"
    },
    logo: {
        height: "70vh",
        margin: theme.spacing(4),
    },
    arrow: {
        position: "fixed",
        bottom: 0,
        left: 0,
        padding: 20,
    }
}));

function TerminalStart({scanDisabled, onCardScan, onCardScanAfter}) {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <CardScanner scanDisabled={scanDisabled} onCardScan={onCardScan} onCardScanAfter={onCardScanAfter} />
            <img alt="Logo" className={classes.logo} src="Logo.svg" />
            <Typography variant="h4" align="center">
                Bitte scanne zunächst deine Kundenkarte
            </Typography>
            <Box className={classes.arrow}><Arrow bottom left blinking lineWidth={15} arrowLength={75}/></Box>
        </Box>
    )
}

export default TerminalStart;