import { LOG_LEVEL } from "../../enums/log";

function sendLog(level, target, message) {
    let error = true;
    var targetUrl = window._env_.RS_HOST.concat("/api/v1/log");
    fetch(encodeURI(targetUrl), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            level: level,
            target: target,
            message: message,
        }),
    }).then(response => {
        if(response.ok) {
            error = false;
        } 
        return response.text();
    }).then(text => {
        if(error) {
            console.error("Error in log request (/api/v1/log):", text);
        }
    }).catch(function(error) {
        console.error("Error in log request (/api/v1/log):", error);
    });
}

class Logger {
    level = LOG_LEVEL.DEBUG;
    target = "";
    separator = "|"

    constructor({ level, target }) {
        this.level = level;
        this.target = target;
    }

    debug(message, ...optionalParams) {
        if (this.level > LOG_LEVEL.INFO) {
            console.log("DEBUG", this.separator, this.target, this.separator, message, ...optionalParams);
            sendLog(LOG_LEVEL.DEBUG, this.target, [message, ...optionalParams].join(" "));
        }
    }

    info(message, ...optionalParams) {
        if (this.level > LOG_LEVEL.WARN) {
            console.log("INFO", this.separator, this.target, this.separator, message, ...optionalParams);
            sendLog(LOG_LEVEL.INFO, this.target, [message, ...optionalParams].join(" "));
        }
    }

    warn(message, ...optionalParams) {
        if (this.level > LOG_LEVEL.ERROR) {
            console.warn("WARN", this.separator, this.target, this.separator, message, ...optionalParams);
            sendLog(LOG_LEVEL.WARN, this.target, [message, ...optionalParams].join(" "));
        }
    }

    error(message, ...optionalParams) {
        console.error("ERROR", this.separator, this.target, this.separator, message, ...optionalParams);
        sendLog(LOG_LEVEL.ERROR, this.target, [message, ...optionalParams].join(" "));
    }
}

export default Logger;