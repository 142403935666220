export function checkShoppingCartState(shoppingCartProducts, reservationProducts){
    var changedShoppingCardProducts = []
    for(var i = 0; i < shoppingCartProducts.length; i++){
        
        var shoppingCartItem = shoppingCartProducts[i];
        var reservationProduct = getReservationProduct(reservationProducts, shoppingCartProducts[i].webProduct);

        if(reservationProduct.quantity < shoppingCartItem.quantity){
            changedShoppingCardProducts.push({
                webProductTitle: shoppingCartItem.webProduct.title,
                count: shoppingCartItem.quantity - reservationProduct.quantity
            })
        }
    }
    return changedShoppingCardProducts;
}

export function validateShoppingCart(dispatch){
    dispatch({
        type: 'correctShoppingCart', 
    });
}

export function getShoppingCartItem(shoppingCartProducts, product) {
    // return ProductCard.getShoppingCartItem(product);
    return shoppingCartProducts[shoppingCartProducts.map((shoppingCartItem) => shoppingCartItem.webProduct.id).indexOf(product.id)];
}

export function getReservationProduct(reservationProducts, webProduct) {
    // return ProductCard.getShoppingCartItem(product);
    return reservationProducts[reservationProducts.map((item) => item.webProduct.id).indexOf(webProduct.id)];
}

export function existsInShoppingCart(state, product) {
    return state.shoppingCartProducts.map((shoppingCartItem) => shoppingCartItem.webProduct.id).includes(product.id);
}

export function addToShoppingCart(webProduct, count, weight, dispatch, state) {
    //ProductCard.addToShoppingCart(product, count);
    if(count > 0 && existsInShoppingCart(state, webProduct)){
        if(getShoppingCartItem(state.shoppingCartProducts, webProduct).quantity < getReservationProduct(state.reservationProducts, webProduct).quantity){
            dispatch({
                type: 'addToShoppingCart',
                shoppingCartProduct: { 
                    webProduct: webProduct, 
                    quantity: count,
                    weight: weight,
                },
            });
        }   
    }else{
        dispatch({
            type: 'addToShoppingCart',
            shoppingCartProduct: { 
                webProduct: webProduct, 
                quantity: count,
                weight: weight,
            },
        });
    }
    
}