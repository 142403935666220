import { Box, IconButton, InputBase, makeStyles, Paper } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
	root: props => ({
		height: 50,
		padding: "2px 16px",
		display: "flex",
		alignItems: "center",
		border: 1,
		borderStyle: "solid",
		borderColor: theme.palette[props.color].main
	}),
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	searchIcon: {
		display: "flex",
		padding: 10,
	}
}));

function SearchBar({color, elevation, value, onSearch}) {
	const [searchValue, setSearchValue] = useState(value);
	const [typingTimeout, setTypingTimeout] = useState(null);
	const classes = useStyles({color: color});

	const performSearch = (value) => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		setSearchValue(value);
		setTypingTimeout(setTimeout(() => onSearch(value), 200));
	}

	return (
		<Paper elevation={elevation} className={classes.root}>
			<InputBase
				className={classes.input}
				placeholder="Suche"
				value={searchValue}
				onChange={(e) => { e.persist(); performSearch(e.target.value);}}
			/>
			<Box className={classes.searchIcon}>
				{searchValue === "" ? 
					<SearchIcon color={color} /> 
				: 
					<IconButton color={color} size="small" onClick={() => performSearch("")}>
						<ClearIcon />
					</IconButton>
				}
			</Box>
			
		</Paper>
	)
}

export default SearchBar;