import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from 'react';

function HelpDialog({open, handleClose}) {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" onClose={handleClose}>
                Hilfe
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Du hast Probleme mit deinem Einkauf beim RegioStore? 
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Zwischen 10 und 20 Uhr sind wir für deine Fragen erreichbar unter
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Tel.: {window._env_.RS_COMPANY_TEL}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Email: {window._env_.RS_COMPANY_MAIL}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default HelpDialog;