import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Snackbar, TextField, Typography } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { LOG_LEVEL } from "../../enums/log";
import Logger from "../utilities/logger";

const useStyles = makeStyles(theme => ({
    barcodeContainer: {
        display: "flex",
        flexDirection: "column",
    },
    barcodeTextField: {
        marginTop: theme.spacing(4),
        marginInline: "auto",
    },
    alert: {
        paddingBlock: 15,
        paddingInline: 25,
    },
}));

function BarcodeDialog({open, handleClose, onManualProductScanAfter}) {
    const classes = useStyles();
    const [barcode, setBarcode] = useState("");
    const [errorText, setErrorText] = useState("");
    const [warningSound] = useState(new Audio("sounds/warning.wav"));     

    const logger = new Logger({ level: LOG_LEVEL.INFO, target: "barcodedialog" });

    const triggerManualScan = (barcode) => {
        logger.info("trigger manual scan with barcode", barcode);
        var targetUrl = window._env_.RS_HOST.concat("/api/v1/product?barcode=" + barcode);
        let error = true;
        fetch(encodeURI(targetUrl), {
            method: 'GET',
        }).then(response => {
            if(response.ok) {
                error = false;
                return response.json();
            } else if (response.status === 404) {
                logger.warn("Product with barcode", barcode, "not found");
                warningSound.play();
                setErrorText("Das Produkt konnte nicht in der Datenbank gefunden werden.");
            } else {
                logger.warn("Unknown error in get product request with barcode", barcode, "(/api/v1/product) Statuscode:", response.status);
            }
        }).then(product => {
            if (!error) {
                onManualProductScanAfter(product);
                setBarcode("");
            }
        }).catch(function(error) {
            logger.error("Error in get product request with barcode", barcode, "(/api/v1/product):", error);
            warningSound.play();
            setErrorText("Fehler beim Abrufen der Produktinformationen: " + error);
        });
    }

    const handleKeypress = e => {
        logger.debug("handle keypress");
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            logger.debug("key is Enter");
            triggerManualScan(barcode);
        }
    };

    const handleScanErrorClose = () => {
        logger.debug("handle scan error close");
        setErrorText("");
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" onClose={handleClose}>
                Produkt manuell hinzufügen
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Gebe den Barcode auf der Verpackung des Produkts ein. 
                </Typography>
                <Box className={classes.barcodeContainer}>
                    <TextField 
                        id="barcodeTextField"
                        placeholder="Barcode"
                        label="Barcode"
                        variant="outlined"
                        value={barcode}
                        onChange={(e) => {e.persist(); setBarcode(e.target.value)}}
                        type="text"
                        onKeyPress={handleKeypress}
                        autoFocus
                        className={classes.barcodeTextField}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleClose}
                >
                    Schließen
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => triggerManualScan(barcode)}
                >
                    Hinzufügen
                </Button>
            </DialogActions>
            <Snackbar
                id="manualProductScanErrorSnackbar"
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorText !== ""} 
                autoHideDuration={10000} 
                onClose={handleScanErrorClose}
            >
                <Alert 
                    id="manualProductScanErrorAlert"
                    variant="filled"
                    className={classes.alert}
                    onClose={handleScanErrorClose} 
                    severity="error"
                >
                    {errorText}
                </Alert>
            </Snackbar>
        </Dialog>
    );
}

export default BarcodeDialog;