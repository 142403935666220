/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Chip, makeStyles, useMediaQuery } from "@material-ui/core";
import React from 'react';
import { LOG_LEVEL } from "../../enums/log";
import Logger from "../utilities/logger";
// eslint-disable-next-line no-unused-vars
const logger = new Logger({ level: LOG_LEVEL.INFO, target: "filterpanel" });
const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: theme.spacing(3),
	},
	filter: props => ({
		display: 'flex',
		justifyContent: props.mobile ? 'left' : 'center',
		overflowX: "scroll",
		padding: theme.spacing(3),
		'& > *': {
			marginInline: theme.spacing(1),
		},
	}),
}));

function FilterPanel({ color, activeColor, heading, labels, activeLabel, onClick }) {
	const mobile = useMediaQuery('(max-width:600px)');
	const classes = useStyles({mobile});

	return (
		labels && labels.size > 0 && <Box className={classes.filter}>
			{Array.from(labels).map(([key, value]) => (
				<Chip
					key={"filterChip" + key}
					label={value}
					size="medium"
					variant="outlined"
					color={key === activeLabel ? activeColor : color}
					onClick={() => onClick(key)}
					onDelete={key === activeLabel ? () => onClick(null) : undefined}
					clickable
				/>
			))}
		</Box>
	);
}

export default FilterPanel;