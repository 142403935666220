/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogContent, DialogTitle, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { LOG_LEVEL } from "../../enums/log";
import { theme } from "../../theme";
import Logger from "../utilities/logger";

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        marginBottom: theme.spacing(3),
    },
}))

function CrateDialog({product, open, handleClose, onSingle, onCrate}) {
    const classes = useStyles();
    const [attentionSoundPlayed, setAttentionSoundPlayed] = useState(false);
    const [attentionSound] = useState(new Audio("sounds/attention.wav"));

    const logger = new Logger({ level: LOG_LEVEL.INFO, target: "cratedialog" });

    useEffect(() =>{
        if (!attentionSoundPlayed){
            logger.debug("crate dialog is displayed. play attention sound.");
            attentionSound.play();
            setAttentionSoundPlayed(true);
        }
    }, [
        logger,
        attentionSound,
        attentionSoundPlayed,
    ]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" onClose={handleClose}>
                Einzelne Flasche oder Kiste?
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing(4)} className={classes.buttonContainer}>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={() => onSingle()}>
                            Flasche
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" color="secondary" fullWidth onClick={() => onCrate()}>
                            Kiste
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CrateDialog;