import { Button, ButtonGroup } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import { theme } from "../../theme";
import { getNumberOfDecimalPlaces, round } from "./numberUtils";

function QuantityControl({ target=null, color, index, unit, quantity, quantitySteps=1, onChangeQuantity }) {

	const changeQuantity = (e, index, quantity, quantitySteps) => {
		let t = target;
		if (t == null) {
			t = e.currentTarget;
		}
		const numberOfDecimals = getNumberOfDecimalPlaces(quantitySteps);
		const newQuantity = round(quantity + quantitySteps, numberOfDecimals);
		onChangeQuantity(e, index, t, newQuantity);
	}

	const getQuantityText = (quantity) => {
		if (unit && unit !== null && unit !== "") {
			return quantity + " " + unit;
		}
		return quantity;
	}

	return (
		<ButtonGroup color={color} aria-label="outlined primary button group">
			<Button color={color} size="small" fill="clear" onClick={(e) => changeQuantity(e, index, quantity, - quantitySteps)}>
				{quantity > quantitySteps ?
				<RemoveIcon slot="icon-only" /> :
				<DeleteIcon slot="icon-only" />}
			</Button>
			<Button color={color} size="small" fill="clear" style={{color: theme.palette.text.primary}} onClick={(e) => e.stopPropagation()}>
				{getQuantityText(quantity)}
			</Button>
			<Button color={color} size="small" fill="solid" onClick={(e) => changeQuantity(e, index, quantity, + quantitySteps)}>
				<AddIcon slot="icon-only" />
			</Button>
		</ButtonGroup>
	);
}

export default QuantityControl;