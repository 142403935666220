export function getProduct(state, productId) {
	return state.products[state.products.map((product) => product.productId).indexOf(productId)]
}

export function getCartItem(state, product) {
	return state.cart.items[state.cart.items.map((item) => item.product.id).indexOf(product.productId)];
}

export function getReservationProduct(reservationProducts, product) {
	return reservationProducts[reservationProducts.map((item) => item.productId).indexOf(product.productId)];
}

export function existsInCart(state, productId, codedBarcode) {
	if (codedBarcode && codedBarcode != null) {
		return state.cart.items.map((item) => item.codedBarcode).includes(codedBarcode);
	}
	return state.cart.items.map((item) => item.product.id).includes(productId);
}

export function getCartItems(state, productId) {
	return state.cart.items.filter((item) => item.product.id === productId);
}

export function getNumberOfItemsInCart(state, product) {
	const items = state.cart.items.filter((item) => product === null || item.product.id === product.productId);
	if (items.length <= 0) {
		return 0;
	}
	return items
		.map((item) => item.priceUnit === "WEIGHT" ? 1 : item.quantity)
		.reduce((number1, number2) => number1 + number2);
}

export function isCodedBarcode(product) {
	return product.barcodeType !== null && product.barcodeType !== "EAN";
}

export function compareCarts(cart1, cart2) {
	if (cart1 === null && cart2 === null) {
		return true;
	}
	if (cart1 !== null && cart2 !== null && cart1.items.length === cart2.items.length) {
		const sortedItemsCart1 = cart1.items.slice().sort((item1, item2) => item1.product.id - item2.product.id || item1.codedBarcode.localeCompare(item2.codedBarcode));
		const sortedItemsCart2 = cart2.items.slice().sort((item1, item2) => item1.product.id - item2.product.id || item1.codedBarcode.localeCompare(item2.codedBarcode));
		return sortedItemsCart1.every((item, index) => {
			return item.product.id === sortedItemsCart2[index].product.id && item.quantity === sortedItemsCart2[index].quantity;
		})
	}
	return false;
}