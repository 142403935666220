import { Box, Card, Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useState } from "react";
import './FlipCard.css';
import ProductCard from "./productCard";

const useStyles = makeStyles(theme => ({
    productImage: {
        textAlign: "center",
        height: "inherit"
    },
    productInfo: {
        paddingInline: theme.spacing(4),
        paddingRight: 5,
        position: "relative",
        height: "100%",
    },
    productTitle: {
        paddingBlockStart: theme.spacing(4),
    },
    sold_out: {
        top: "2em",
        left: "-4em",
        color: "#fff",
        display: "block",
        position:"absolute",
        textAlign: "center",
        textDecoration: "none",
        letterSpacing: ".06em",
        backgroundColor: "#A00",
        padding: "0.5em 5em 0.4em 5em",
        textShadow: "0 0 0.75em #444",
        boxShadow: "0 0 0.5em rgba(0,0,0,0.5)",
        font: "bold 16px/1.2em Arial, Sans-Serif",
        WebkitTextShadow: "0 0 0.75em #444",
        WebkitBoxShadow: "0 0 0.5em rgba(0,0,0,0.5)",
        WebkitTransform: "rotate(-45deg) scale(0.95,1)",
        zIndex:"10",
    },
}));

function ProductFlipCard({product, showStock, onCardClick, showBuyButton = false, showRegionalLabels = true}) {
    const mobile = useMediaQuery('(max-width:600px)')
    const classes = useStyles();
    const [isFlipped, setIsFlipped] = useState(false);

    return (
        <Box className="flip-card">
            <Box className={isFlipped ? ["flip-card-inner isFlipped"] : "flip-card-inner"}>
                <ProductCard 
                  className="flip-card-front"
                  product={product} 
                  showStock={showStock} 
                  onCardClick={onCardClick}
                  onInfoClick={() => setIsFlipped(!isFlipped)}
                  showBuyButton={showBuyButton} 
                  showRegionalLabels={showRegionalLabels}
                />
                <Card className="flip-card-back" onClick={() => {setIsFlipped(!isFlipped); onCardClick(product);}}>
                    <Grid container>
                        <Grid style={mobile ? {marginTop: 15, marginBottom: 15}:{}} item xs={12} className={classes.productImage}>
                            <Box style={{height: "100%", display: "flex", flexDirection: "column"}}>
                                <CloseIcon htmlColor="#6bb892" style={{position: "absolute", left: "0px", top: "0px", padding: "5px"}}></CloseIcon>
                                <Typography gutterBottom variant="subtitle2" className={classes.productTitle}>
                                    {product.title}
                                </Typography>
                                <Typography style={{fontSize: "clamp(11px, 0.80vw, 18px)"}} variant="body1">
                                    {product.product.description}
                                </Typography>
                            </Box> 
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Box>
    );
}

export default ProductFlipCard;