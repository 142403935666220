import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	content: {
    paddingInline: theme.spacing(4),
    paddingBlockEnd: theme.spacing(4),
	},
	heading: {
		fontWeight: "bold",
	}
}));

function DataArea({className, color, hidden, heading, children}) {
	const classes = useStyles();

	return (
		<Box hidden={hidden} className={className} border={1} borderRadius={6} borderColor={color + ".main"}>
			<Box className={classes.content}>
				<Typography className={classes.heading} variant="caption" color={color} gutterBottom>
					{heading}
				</Typography>
				{children}
			</Box>
		</Box>
	)
}

export default DataArea;