import { Box, Button, makeStyles, Popover, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: 12,
	}
}))

function LoginPopover({anchor, onLogin, onContinue, onClose}) {
	const classes = useStyles();

	return (<Popover
		id="loginPopover"
		open={anchor !== null}
		anchorEl={anchor}
		onClose={onClose}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
	>
		<Box className={classes.root}>
			<Typography variant="h6" gutterBottom>
				Du bist noch nicht angemeldet
			</Typography>
			<Button variant="contained" color="primary" onClick={onLogin}>
				Jetzt Anmelden
			</Button>
			<Button variant="text" color="secondary" onClick={onContinue}>
				Weiter ohne Anmeldung
			</Button>
		</Box>
	</Popover>);
}

export default LoginPopover;