/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { LOG_LEVEL } from "../../enums/log";
import { theme } from "../../theme";
import ProductImage from "../products/productImage";
import { getAmount, getDisplayPrice } from "../products/productUtils";
import Logger from "../utilities/logger";
import { formatNumber } from "../utilities/numberUtils";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 16,
        display: "flex",
        flexDirection: "column",
    },
    productInfo: {
        marginBlockStart: 16,
    },
    displayPrice: {
        paddingInlineStart: theme.spacing(2),
    },
}))

function ScaleDialog({product, open, handleClose, onScaleFinished}) {
    const classes = useStyles();
    const [scale, setScale] = useState(0);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const logger = new Logger({ level: LOG_LEVEL.DEBUG, target: "scaledialog" });

    useEffect(() => {
        var conn = new WebSocket(window._env_.RS_RESOURCE_BRIDGE.concat("/consumer"))
        conn.onclose = (e) => {
            setError("No Connection to Resource Bridge");
        }
        conn.onopen = (e) => {
            conn.send(JSON.stringify({
                "command": "WEIGHT"
            }));
        }
        conn.onmessage = (e) => {
            if (e.data) {
                setScale(0);
                let data = JSON.parse(e.data)
                if (data.command === "WEIGHT" && data.detail) {
                    if (data.detail.error && data.detail.error != null) {
                        logger.warn(data.detail.error);
                        setError(data.detail.error);
                    } else {
                        setError(null);
                        if(data.detail.weight > 0) {
                            setSuccess(true);
                        }
                        if(data.detail.unit === "kg") {
                            logger.debug("returned", data.detail.weight, "kg as weight");
                            setScale(data.detail.weight * 1000);
                        } else if(data.detail.unit === "g") {
                            logger.debug("returned", data.detail.weight, "g as weight");
                            setScale(data.detail.weight);
                        }
                    }
                } else if (data.command === "ERROR") {
                    if (data.detail) {
                        logger.warn(data.detail);
                        setError(data.detail);
                    } else {
                        let error = "Unknown Error"
                        logger.warn(error);
                        setError(error);
                    }
                }
            }
            logger.info(e);
        }
        return () => {
            conn.send(JSON.stringify({
                "command": "WEIGHT"
            }));
            conn.close();
        }
    }, []);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="dialog-title"
        >
            <DialogTitle id="dialog-title" onClose={handleClose}>
                Lege die Produkte auf die Wage
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing(4)}>
                    <Grid item xs={3}>
                        <ProductImage
                            src={window._env_.RS_HOST.concat(product.imagePath)}
                        />
                    </Grid>
                    <Grid item xs={9} className={classes.productInfo}>
                        <Typography gutterBottom variant="h4">
                            {product.title}
                        </Typography>
                        <Typography variant="body1" className={classes.displayPrice}>
                            {getDisplayPrice(
                                product.product.priceUnit,
                                product.product.pricePerUnit
                            )}
                        </Typography>
                        <Typography gutterBottom variant="h2" align="center">
                            {scale + " g"}
                        </Typography>
                        <Typography variant="caption" color="error" align="center">
                            {error}
                        </Typography>
                        <Typography variant="body1" align="right">
                            {"Preis: " + formatNumber(getAmount(
                                product.product.priceUnit,
                                scale / 1000,
                                product.product.pricePerUnit,
                                product.product.discount,
                            ),2) + " €"}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Schließen
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!success}
                    onClick={() => onScaleFinished(product, scale / 1000)} 
                >
                    Hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ScaleDialog;