import {
	Badge,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Popover,
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getWeightText } from "../products/productUtils";
import { getNumberOfItemsInCart } from "./deliveryAction";

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: "none",
	},
	popoverListItem: {
		padding: 6,
	},
}));

function ShoppingCartButton({ state, className }) {
	const classes = useStyles();
	const [anchor, setAnchor] = useState(null);

	const open = Boolean(anchor);

	const handleMouseEnter = (e) => {
		if (
			state &&
			state.cart &&
			state.cart.items &&
			state.cart.items.length > 0
		) {
			setAnchor(e.currentTarget);
		}
	};

	const getListItemText = (item) => {
		let quantityText = item.quantity + " x";
		if (item.priceUnit === "WEIGHT") {
			quantityText = getWeightText(item.quantity);
		}
		return quantityText + " " + item.description;
	};

	return (
		<Box className={className}>
			<IconButton
				aria-owns={open ? "shoppingCartPopover" : undefined}
				aria-haspopup="true"
				component={Link}
				to="/cart"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={() => setAnchor(null)}
			>
				<Badge
					badgeContent={getNumberOfItemsInCart(state, null)}
					overlap="circular"
					color="primary"
				>
					<ShoppingCartIcon fontSize="large" />
				</Badge>
			</IconButton>
			<Popover
				id="shoppingCartPopover"
				className={classes.popover}
				open={open}
				anchorEl={anchor}
				onClose={() => setAnchor(null)}
				disableRestoreFocus
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<List component={Box}>
					{state.cart.items.map((item, index) => (
						<ListItem
							key={"shoppingCartPopoverListItem" + index}
							component={Box}
						>
							<ListItemText primary={getListItemText(item)} />
						</ListItem>
					))}
				</List>
			</Popover>
		</Box>
	);
}

export default ShoppingCartButton;
