// Font Awesome Imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { createContext, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Route, Switch, useLocation
} from "react-router-dom";
import './App.css';
import Footer from './components/footer/footer';
import Header from "./components/header/header";
import CookieBanner from "./components/utilities/cookieBanner";
import Logger from "./components/utilities/logger";
import { TopButton } from "./components/utilities/topButton";
import { LOG_LEVEL } from "./enums/log";
import { AuthProvider } from "./provider/authProvider";
import { HeaderProvider } from "./provider/headerProvider";
import reducer from './reducer';
import { footerLinks, headerLinks, routingItems } from './routingItems';
import { theme } from './theme';
import { useStorage } from './useStorage';
// eslint-disable-next-line no-unused-vars
const logger = new Logger({ level: LOG_LEVEL.DEBUG, target: "app" });

// Add FontAwesome Icons to Library
library.add(fab, fas);

function makeid(length) {
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < length; i++ ) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const AppContext = createContext();

const AppContextProvider = (props) => {
	const localStorageVersionNumber = "1.0.9";
	const [products, setProducts] = useStorage(window.localStorage, 'products'.concat(localStorageVersionNumber), []);
	const [cart, setCart] = useStorage(window.localStorage, 'cart'.concat(localStorageVersionNumber), {items: []});
	const [reservationProducts, setReservationProducts] = useStorage(window.localStorage, 'reservationProducts'.concat(localStorageVersionNumber), []);
	const [deviceId, setDeviceId] = useStorage(window.localStorage, 'deviceId', makeid(30));
	const [favorites, setFavorites] = useStorage(window.localStorage, 'favorites'.concat(localStorageVersionNumber), []);
	const [selectedFetchtime, setSelectedFetchtime] = useStorage(window.localStorage, 'selectedFetchtime'.concat(localStorageVersionNumber), null);
	const [cheat, setCheat] = useStorage(window.localStorage, 'cheat'.concat(localStorageVersionNumber), []);

	const [state, dispatch] = useReducer(reducer, {
		products: products,
		reservationProducts: reservationProducts,
		favorites: favorites,
		selectedFetchtime: selectedFetchtime,
		cheat: cheat,
		deviceId: deviceId,
		cart: cart
	});

	const value = {state, dispatch};

	useEffect(() => {
		setProducts(state.products)
		setReservationProducts(state.reservationProducts)
		setFavorites(state.favorites)
		setSelectedFetchtime(state.selectedFetchtime)
		setCheat(state.cheat)
		setDeviceId(state.deviceId)
		setCart(state.cart)
	}, [
		state,
		setProducts,
		setReservationProducts,
		setFavorites,
		setSelectedFetchtime,
		setCheat,
		setDeviceId,
		setCart,
	]);

	return (
		<AppContext.Provider value={value}>{props.children}</AppContext.Provider>
	);
}

function ScrollToTop() {
	const location = useLocation();
	const [previousPath, setPreviousPath] = useState(null);

	useLayoutEffect(() => {
		if (previousPath === null || previousPath !== location.pathname) {
			document.documentElement.scrollTo({
				top:0,
				left:0,
				behavior: "instant"
			});
			setPreviousPath(location.pathname)
		}
	}, [location, previousPath]);

	return null;
}

function App() {
	const [, forceUpdate] = useReducer(x => x + 1,0);
	const headerHeight = process.env.REACT_APP_DISPLAY_NAV === "False" ? 0 : 4; //rem

	useEffect(() => {
		function updateSize() {
			forceUpdate();
			// console.log("resize. innerWidth: " + window.innerWidth);
		}
		window.addEventListener('resize', updateSize);
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const addRedirect = () => {
		let redirect = null;
		const routes = process.env.REACT_APP_ROUTES.split(",");
		for (var i = 0; i < routingItems.length; i++) {
			const routingItem = routingItems[i];
			if (routes[0] === routingItem.id) {
				redirect = <Redirect to={routingItem.path} />
			}
		}
		return redirect;
	}

	return (
		<AppContextProvider>
			<MuiThemeProvider theme={theme}>
				<AuthProvider>
					<Router onUpdated>
						<HeaderProvider>
							{ process.env.REACT_APP_DISPLAY_NAV === "False" ? null : <Header height={headerHeight} links={headerLinks} /> }
							<ScrollToTop />
							<Switch >
								{routingItems.map((routingItem) => (
									process.env.REACT_APP_ROUTES.indexOf(routingItem.id) > -1 ? <Route key={routingItem.id} path={routingItem.path}>
										{React.createElement(routingItem.item, {headerHeight: headerHeight})}
									</Route> : null
								))}
								{addRedirect()}
							</Switch>
							<CookieBanner />
							<TopButton />
							{ process.env.REACT_APP_DISPLAY_NAV === "False" ? null : <Footer links={footerLinks} /> }
						</HeaderProvider>
					</Router>
				</AuthProvider>
			</MuiThemeProvider>
		</AppContextProvider>
	);
}

export default App;
