import { Fab, makeStyles } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
    button: {
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: 30,
    },
});

export function TopButton(props) {
    const classes = useStyles();
    const [showButton, setShowButton] = useState(false);   

    var handleScroll = () => {
        window.scrollY > 0 ? setShowButton(true) : setShowButton(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll )
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    })

    return (
        <div>
        {showButton ? <Fab 
            aria-label="top" 
            color="primary" 
            href="#top"
            className={classes.button} 
        >
            <KeyboardArrowUpIcon fontSize="large" />
        </Fab> : null }
        </div>
    )
}