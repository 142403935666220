import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

function SimpleDialog({open, onClose, title, content, acceptButtonText, onAcceptButtonClick, declineButtonText, onDeclineButtonClick}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			aria-labelledby="simple-dialog-title"
		>
			<DialogTitle id="simple-dialog-title" onClose={onClose}>
				{title}
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">
					{content}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={onDeclineButtonClick ? onDeclineButtonClick : onClose}>
					{declineButtonText}
				</Button>
				<Button variant="contained" color="secondary" onClick={onAcceptButtonClick}>
					{acceptButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SimpleDialog;