import { useCallback, useEffect, useRef, useState } from 'react';

function useInfiniteScroll() {
  const [page, setPage] = useState(1);
  const loaderRef = useRef(null);

  const handleObserver = useCallback((entries) => {
    const [target] = entries;
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (loaderRef.current) observer.observe(loaderRef.current);
  }, [handleObserver]);

  return { loaderRef, page };
}

export default useInfiniteScroll;