/* eslint-disable react-hooks/exhaustive-deps */
import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { LOG_LEVEL } from "../../enums/log";
import Logger from "../utilities/logger";

// eslint-disable-next-line no-unused-vars
const logger = new Logger({ level: LOG_LEVEL.INFO, target: "productImage" });
const useStyles = makeStyles({
	root: {
		width: "100%",
	},
	image: {
		height: "100%",
		width: "100%",
		objectFit: "contain",
	},
	imageHide: {
		height: "100%",
		width: "100%",
		objectFit: "contain",
		display: "none"
	}
});

function ProductImage({src, alt}) {
	const classes = useStyles();
	const [imagePath, setImagePath] = useState(null);
	const [height, setHeight] = useState(0);
	const [imgLoaded, setImgLoaded] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		if (ref.current != null) {
			setHeight(ref.current.offsetWidth);
		}
	}, [ref]);

	useEffect(() => {
		if (src !== imagePath) {
			setImagePath(src);
			setImgLoaded(false);
		}
	}, [src]);

	return (
		<Box className={classes.root} ref={ref} style={{height: height}}>
			<img 
				src={imagePath}
				className={!imgLoaded ? classes.imageHide : classes.image}
				alt={alt}
				onLoad={() => setImgLoaded(true)}
			/>
			{!imgLoaded &&
			<ContentLoader 
				speed={2}
				height={height}
				viewBox="0 0 1000 1000"
				backgroundColor="#d9d9d9"
				foregroundColor="#ededed">
				<path className="st0" d="M466.2,773.4c-3.6-15.1-73-279.2,107.7-428.9c16.4-16.2,146.6-140.3,315.6-117.3c40.2,5.5,81,12.5,102.9,22.9
					c-6.6,20.7-25.4,57.5-38.2,95.7c-13.7,40.8-27.3,80.1-31,90.4c-37.5,103.6-136.9,198.1-145.6,206.3
					C671.7,741.8,549.2,775,491,789.5c35.9-63.5,55.1-127.7,107.2-200.9c68.1-95.7,92.4-114,161.2-171.9
					C708.8,451,675.9,472.8,587.4,571C510.6,656.5,499.8,727.6,466.2,773.4z"/>
				<path className="st0" d="M28.7,228.8c-10.9,30-25.1,80.6-22.5,143.9C6.8,387.4,11,434,30.9,491.4c50,144.3,170.4,206.2,186.8,215.4
					c93,52.5,182.3,56.2,225.9,55c-4-16.9-7.7-35.6-10.3-55.9c-1.7-13.1-2.8-25.7-3.5-37.5c-27.4-4.7-108.4-22.2-176-93.6
					c-56.2-59.3-82-130.6-87.8-159.1c8.7,27.6,43,103.4,113,159.5c60.3,48.4,122.5,61.9,151.5,66.3c0.3-25,2.4-54.6,8.2-87.4
					c5.9-33.2,16.2-62.1,24.7-85.9c-8.9-17.6-21.8-35.6-40.4-55.4c-27.4-29.2-50.7-43.4-70.6-53c-31.5-15.3-45.7-18.5-69.8-26
					C131.5,287.1,49.2,238.2,28.7,228.8z"/>
			</ContentLoader>
			}
		</Box>
	);
}

export default ProductImage;