import { LinearProgress } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../provider/authProvider";

function Callback(props) {
  const history = useHistory()
  const auth = useAuth();

  useEffect(() => {
    if (auth.isInitialized()) {
      auth.callback().then((origin) => {
        history.push(origin);
      });
    }
  }, [auth, history]);

  return <LinearProgress style={{width: "100%"}} />;
}

export default Callback;