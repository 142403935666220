import { Badge, Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useContext, useRef } from "react";
import { AppContext } from "../../App";
import QuantityControl from "../utilities/quantityControl";
import {
  existsInCart,
  getCartItem,
  getNumberOfItemsInCart,
  isCodedBarcode,
} from "./deliveryAction";

const useStyles = makeStyles((theme) => ({
  quantityControlContainer: {
    padding: 8,
  },
  addToCartButton: {
    width: 32,
    height: 32,
  },
}));

function ShopQuantityControl({ index, product, onQuantityChange }) {
  const quantityControlContainer = useRef();
  const classes = useStyles();
  const { state } = useContext(AppContext);

  const changeQuantity = (e, index, target, quantity) => {
    e.stopPropagation();
    if (index !== null && index !== undefined && index >= 0) {
      onQuantityChange(index, target, quantity);
    } else {
      onQuantityChange(product, target, quantity);
    }
  };

  return (
    <Box
      className={classes.quantityControlContainer}
      ref={quantityControlContainer}
    >
      {product ? (
        existsInCart(state, product.productId) && !isCodedBarcode(product) ? (
          <QuantityControl
            target={quantityControlContainer.current}
            color="default"
            index={index}
            unit={product.priceUnit === "WEIGHT" ? "kg" : null}
            quantity={getCartItem(state, product).quantity}
            quantitySteps={product.quantitySteps}
            onChangeQuantity={changeQuantity}
          />
        ) : (
          <Tooltip
            key={"TooltipAddToCard" + product.productId}
            title="Zum Warenkorb hinzufügen"
          >
            <IconButton
              color="inherit"
              className={classes.addToCartButton}
              onClick={(e) =>
                changeQuantity(
                  e,
                  index,
                  quantityControlContainer.current,
                  product.quantitySteps
                )
              }
            >
              <Badge
                badgeContent={getNumberOfItemsInCart(state, product)}
                overlap="circular"
                color="primary"
              >
                {existsInCart(state, product.productId) ? (
                  <ShoppingCartIcon />
                ) : (
                  <AddShoppingCartIcon />
                )}
              </Badge>
            </IconButton>
          </Tooltip>
        )
      ) : null}
    </Box>
  );
}

export default ShopQuantityControl;
