import { Box, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { LOG_LEVEL } from "../../enums/log";
import { useAuth } from "../../provider/authProvider";
import { theme } from "../../theme";
import Logger from "../utilities/logger";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexGrow: 2,
		width: "100%",
		justifyContent: "center",
		color: "white",
	},
	content: {
		maxWidth: 1152,
		padding: 30,
		marginTop: 20,
		margin: "auto",
		background: theme.palette.error.main,
	},
}));

export function Register(props) {
	const classes = useStyles();
	const auth = useAuth();
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState({type: "success", value: null});

	const logger = new Logger({ level: LOG_LEVEL.INFO, target: "register" });

	useEffect(() => {
		if (auth.isInitialized() && !loading && message.value === null) {
			setLoading(true);
			let error = true;
			var targetUrl = window._env_.RS_HOST.concat("/api/v2/regiostore/customer");
			auth.request(encodeURI(targetUrl), {
				method: 'POST',
			}, true).then(response => {
				if(response.ok) {
					error = false;
					return response.text();
				}
			}).then(text => {
				setMessage({type: error ? "error" : "success", value: text});
				setLoading(false);
			}).catch(function(error) {
				logger.info("Error in customer request (/api/v2/regiostore/customer):", error);
				setMessage({type: "error", value: "Fehler beim Senden der Registrierung: " + error})
				setLoading(false);
			});
		}
	}, [logger, auth, loading, message]);

	return (
		<Box className={classes.root}>
			<Box 
				id={props.id} 
				boxShadow={3} 
				borderRadius={16} 
				className={classes.content} 
				style={{background: message.type === "error" ? theme.palette.error.main : theme.palette.secondary.main}}>
				<Typography variant="h6" align="center">
					{message.value}
				</Typography>
			</Box>
		</Box>
	);
}