import { Box, Button, ButtonGroup, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import RemoveIcon from '@material-ui/icons/Remove';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import { PRICE_UNIT } from "../../enums/product";
import { addToShoppingCart, existsInShoppingCart, getReservationProduct, getShoppingCartItem } from "../reservation/reservationAction";
import ProductImage from "./productImage";
import ProductLabel from "./productLabel";
import { getDisplayPrice, getLabelList, getStock } from "./productUtils";

const useStyles = makeStyles(theme => ({
    productContainer: {
        height: "100%",
    },
    productImage: {
        textAlign: "center",
        height: "inherit",
        paddingBottom: 20
    },
    productInfo: {
        paddingInline: theme.spacing(4),
        paddingRight: 5,
        position: "relative",
        height: "100%",
    },
    detailModalProductInfo: {
        paddingInline: theme.spacing(4),
        paddingTop: "3vh",
        position: "relative",
    },
    productImageDetailModal: {
        paddingInline: theme.spacing(4),
        paddingRight: 5,
        width: "100%",
    },
    productTitle: {
        paddingBlockStart: theme.spacing(4),
        paddingRight: 15,
    },
    productLabels: {
        minHeight: 25,
        display: "flex",
        justifyContent: "left",
        position: "absolute",
        bottom: 5,
        right: 5,
    },
    infoButton: {
        position: "absolute", 
        right: 1, 
        top: 1, 
        padding: 1,
    },
    buyButton: {
        position: "absolute", 
        right: 5, 
        bottom: 5, 
        padding: 5,
    },
    soldOut: {
        top: "2em",
        left: "-4em",
        color: "#fff",
        display: "block",
        position:"absolute",
        textAlign: "center",
        textDecoration: "none",
        letterSpacing: ".06em",
        backgroundColor: "#A00",
        padding: "0.5em 5em 0.4em 5em",
        textShadow: "0 0 0.75em #444",
        boxShadow: "0 0 0.5em rgba(0,0,0,0.5)",
        font: "bold 16px/1.2em Arial, Sans-Serif",
        WebkitTextShadow: "0 0 0.75em #444",
        WebkitBoxShadow: "0 0 0.5em rgba(0,0,0,0.5)",
        WebkitTransform: "rotate(-45deg) scale(0.95,1)",
        zIndex:"10",
    },
}));

function ProductCard({className, product, showStock, onCardClick, onInfoClick, showBuyButton = false, showRegionalLabels = true, showInfoButton = false}) {
    const mobile = useMediaQuery('(max-width:600px)')
    const classes = useStyles();
    const { state, dispatch } = useContext(AppContext);
    const [openProductDetailModal, setOpenProductDetailModal] = useState(false);
    const [productDetailModal, setProductDetailModal] = useState(null);

    const openDetailModal = (product, visible) => {
        setProductDetailModal(product);
        setOpenProductDetailModal(visible);
    }

    function addShoppingCartIcon(product) {
        if (existsInShoppingCart(state, product)) {
            return(
                <div className={classes.buyButton} color="white">
                    {
                    product.product.priceUnit === 'WEIGHT' ? 
                        <Typography color="textSecondary" variant="body2" align="right">
                            = {((getShoppingCartItem(state.shoppingCartProducts, product).quantity * product.product.pricePerUnit).toFixed(2) * getShoppingCartItem(state.shoppingCartProducts, product).weight).toFixed(2) + '€'}
                        </Typography>
                        : 
                        <Typography color="textSecondary" variant="body2" align="right">
                            = {((getShoppingCartItem(state.shoppingCartProducts, product).quantity * product.product.pricePerUnit)).toFixed(2) + '€'}
                        </Typography>
                    }
                    <ButtonGroup  color="primary" aria-label="outlined primary button group">
                        <Button className="removeFromShoppingCartButton" color="primary" size="small" fill="clear" onClick={() => addToShoppingCart(product, -1, getShoppingCartItem(state.shoppingCartProducts, product).weight, dispatch, state)}>
                            <RemoveIcon slot="icon-only"/>
                        </Button>
                        <Button size="small" fill="clear">
                            {getShoppingCartItem(state.shoppingCartProducts, product).quantity}
                        </Button>
                        <Button className="addToShoppingCartButton" size="small" color="primary" fill="solid" onClick={() => addToShoppingCart(product, 1, getShoppingCartItem(state.shoppingCartProducts, product).weight, dispatch, state)}>
                            <AddIcon slot="icon-only"/>
                        </Button>
                    </ButtonGroup>
                </div>
            )
        }else{
            return(
                <IconButton className={classes.buyButton} color="primary" onClick={() => addToShoppingCart(product, 1, getReservationProduct(state.reservationProducts, product).weight, dispatch, state)}>
                    <ShoppingCartIcon />   
                </IconButton>
            )
        }        
    }

    return (
        <Card style={{position: "relative"}} onClick={() => onCardClick(product)}>
        {showBuyButton && product.product.stock < 1 ? <Link to="#" className={classes.soldOut} >Ausverkauft</Link> : <></>}
            <Grid container className={classes.productContainer} >
                <Grid style={mobile ? {marginTop: 15, marginBottom: 15}:{}} item xs={5} className={classes.productImage}>
                    <Box className={classes.productInfo}>
                        <ProductImage src={product.imagePath !== null ? window._env_.RS_HOST.concat(product.imagePath) : null} />
                    </Box>
                    <ProductLabel 
                            className={classes.productLabels}
                            labels={getLabelList(product)}
                        />
                </Grid>
                <Grid item xs={7}>
                    <Box className={classes.productInfo}>
                        <Typography gutterBottom variant="subtitle1" className={classes.productTitle}>
                            {product.title}
                        </Typography>
                        <Typography variant="body2" color="error" hidden={product.hint === ""}>
                            {product.hint}
                        </Typography>
                        <Typography variant="body1" hidden={product.product.priceUnit === PRICE_UNIT.PIECE}>
                            {product.description}
                        </Typography>
                        <Typography variant="body1">
                            {getDisplayPrice(product.product.priceUnit, product.product.pricePerUnit, product.product.taxes)}
                        </Typography>
                        <Typography variant="body2" hidden={!showStock} color={product.product.stock > 0 ? "secondary" : "error"}>
                            {product.product.stock < 20 ?  getStock(product.product.priceUnit, product.product.stockType, product.product.stock) : <></>}
                        </Typography>
                        
                        
                        {showInfoButton ? 
                        <IconButton color="secondary" onClick={() => openDetailModal(product, true)} className={classes.infoButton}>
                            <InfoIcon />
                        </IconButton> : <></>}
                        {showBuyButton && product.product.stock > 0 ? addShoppingCartIcon(product) : null}
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={openProductDetailModal} onClose={() => setOpenProductDetailModal(false)}>
                <DialogTitle sx={{ m: 0, p: 2 }} style={{position: "relative"}}>
                    {product.title}
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenProductDetailModal(false)}
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                        <Grid container className={classes.productContainer} >
                            <Grid item xs={6}>
                                <Box className={classes.productImageDetailModal}>
                                    <ProductImage src={product.imagePath !== null ? window._env_.RS_HOST.concat(product.imagePath) : null} />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={classes.detailModalProductInfo}>
                                    {/* <Typography hidden={!showStock}>
                                        {product.description}
                                    </Typography> */}
                                    <Typography variant="body1">
                                        {getDisplayPrice(product.product.priceUnit, product.product.pricePerUnit, product.product.taxes)}
                                    </Typography>
                                    <Typography variant="body2" hidden={!showStock} color={product.product.stock > 0 ? "secondary" : "error"}>
                                        {product.product.stock < 20 ?  getStock(product.product.priceUnit, product.product.stockType, product.product.stock) : <></>}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography variant="body1">
                            <strong>Beschreibung:</strong> {productDetailModal ? productDetailModal.product.description : "-"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Zutaten:</strong> {productDetailModal && productDetailModal.product.ingredients ? productDetailModal.product.ingredients : "-"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Hersteller:</strong> {productDetailModal ? productDetailModal.product.producer : "-"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Marke:</strong> {productDetailModal ? productDetailModal.product.brand : "-"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Herkunft:</strong> {productDetailModal ? productDetailModal.product.origin : "-"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Qualität:</strong> {productDetailModal ? productDetailModal.product.organicCertification : "-"}
                        </Typography>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </Card>
    );
}

export default ProductCard;